.leaflet-lf.resize-marker {
    background: url('../../assets/img/mapping/resize-handle.png') no-repeat;
    cursor: move;
}

.leaflet-lf.move-marker {
    /*background: url('../../assets/img/mapping/move-handle.png') no-repeat;*/
    cursor: move;
}
.leaflet-lf.move-marker:before {
    content: "\f047";
    font-family: FontAwesome;
    left:-5px;
    position:absolute;
    font-size: 170%;
    top:-5px;
}

.leaflet-lf.button-container {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 5px;
}
.leaflet-lf.button-container > *:not(:first-child) {
    margin-left: 5px;
}